import axios from "axios";

const {VUE_APP_ECOM_PROXY_API} = process.env;
// const baseURL = VUE_APP_ECOM_PROXY_API || "https://npx-swift.be-ukraine.com/swift-proxy/api/v1";
// const baseURL = VUE_APP_ECOM_PROXY_API || "https://npx-swift-test.be-ukraine.com/swift-proxy/api/v1";

export const instance = axios.create({
    baseURL: VUE_APP_ECOM_PROXY_API
});

const addToken = (token) => {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const removeToken = ()=> {
    instance.defaults.headers.common.Authorization = "";
}
const login = async(value) => {
    const {data} = await instance.post("/auth/login", {value});
    addToken(data.token);
    return data;
}

const getCurrent = async(token) => {
    addToken(token);
    try {
        const {data} = await instance.get("/auth/current");
        return data;
    } catch (error) {
        removeToken();
        throw error;
    }
}

const changePassword = async (value) => {
    const {data} = await instance.post("/auth/change-password", {value});
    return data;
}

//
// export const widgetStart = async (value) => {
//     console.log(value);
//     const {data} = await instance.post("/widget_start", {value});
//     return data;
// }
//
// export const findContract = async (businessKey, contract_number)=> {
//     const value = {
//         contract_number
//     };
//     const {data} = await instance.post("/find_contract", {value, businessKey});
//     return data;
// }
//
// export const getPlatonParametres = async (businessKey, value)=> {
//     const {data} = await instance.post("/platon_parameters", {businessKey, value});
//     return data;
// }
//
// export const sendReceipt = async (businessKey, receipt_email)=> {
//     const value = {
//         receipt_email
//     };
//     const {data} = await instance.post("/send_receipt", {businessKey, value});
//     return data;
// }

export default {
    login,
    getCurrent,
    changePassword,
    removeToken,
}

