import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/en",
    name: "Login-en",
    component: Login,
  },
  {
    path: "/uk",
    name: "Login-uk",
    component: Login,
  },
  {
    path: "/remember-password",
    name: "Remember Password",
    component: () => import(/* webpackChunkName: "about" */ "../views/RememberPassword.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import(/* webpackChunkName: "about" */ "../views/DiagramPage.vue"),
  },
  {
    path: "/tracker-out",
    name: "Tracker out",
    component: () => import(/* webpackChunkName: "about" */ "../views/Tracker.vue"),
  },
  {
    path: "/tracker-in",
    name: "Tracker in",
    component: () => import(/* webpackChunkName: "about" */ "../views/TrackerIn.vue"),
  },
  {
    path: "/srp-out",
    name: "Stop And Recall Out",
    component: () => import(/* webpackChunkName: "about" */ "../views/StopAndRecall.vue"),
  },
  {
    path: "/srp-in",
    name: "Stop And Recall In",
    component: () => import(/* webpackChunkName: "about" */ "../views/StopAndRecallIn.vue"),
  },
  {
    path: "/gcov",
    name: "gCov",
    component: () => import(/* webpackChunkName: "about" */ "../views/gCov.vue"),
  },
  {
    path: "/dict",
    name: "Dictionaries",
    component: () => import(/* webpackChunkName: "about" */ "../views/Dictionaries.vue"),
  },
  {
    path: "/correspondents",
    name: "Correspondents",
    component: () => import(/* webpackChunkName: "about" */ "../views/Correspondents.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import(/* webpackChunkName: "about" */ "../views/Admin.vue"),
    children: [
      {
        path: "/admin/users",
        name: "AdminUsers",
        component: () => import(/* webpackChunkName: "about" */ "../views/AdminUsers.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const operatorAllow = ["/tracker-out", "/tracker-in", "/dict", "/correspondents"];

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");
  const { user_role } = store.state;
  if (token && !user_role) {
    store.dispatch("fetchAccessToken");
  }
  const { fullPath } = to;
  if (fullPath === "/en") {
    next();
  } else if (fullPath === "/uk") {
    next();
  } else if (fullPath !== "/" && !token && fullPath !== "/en" && fullPath !== "/uk") {
    next("/");
  } else if (fullPath === "/" && token) {
    next("/dashboard");
  } else if (user_role === "operator" && !operatorAllow.includes(fullPath)) {
    console.log("redirect ro /tracker-out");
    next("/dashboard");
  }
  // if (to.fullPath !== '/') {
  //   if (!store.state.accessToken) {
  //     next('/login');
  //   }
  // }
  // if (to.fullPath === '/login') {
  //   if (store.state.accessToken) {
  //     next('/users');
  //   }
  // }
  else {
    next();
  }
});

export default router;
