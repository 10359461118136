import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import store from "./store";
import router from "./router";
import i18n from "./i18n";
// import Notifications from "@kyvg/vue3-notification";

import "element-plus/dist/index.css";

import "./variables.css";

import VForm3 from "vform3-builds";
import "vform3-builds/dist/designer.style.css";

const app = createApp(App);
app.config.warnHandler = () => null;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(store).use(router).use(ElementPlus).use(i18n).use(VForm3).mount("#app");
