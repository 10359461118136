<template>
  <notifications />
  <div class="header" v-if="$store.state.token">
    <div class="header-row">
      <div class="header-right-row">
        <router-link class="logo" to="/tracker-out">
          <!--          <img src="./assets/logo.png" alt="" />-->
        </router-link>
        <el-menu :ellipsis="false" class="el-menu-demo" mode="horizontal">
          <el-menu-item index="7">
            <router-link to="/dashboard" class="link">
              <span class="link-text">{{ $t("app.header.dashboard") }}</span>
              <span class="link-icon">
                <img src="./assets/dashboard.png" alt="" width="18" />
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="1">
            <router-link to="/tracker-out" class="link">
              <span class="link-text">{{ $t("app.header.trackerOut") }}</span>
              <span class="link-icon">
                <img src="./assets/arrow-up.png" alt="" />
              </span>
              <div v-if="$store.state.transaction_out" class="link-trasactions-wrap">
                <el-tag
                  v-if="Number($store.state.transaction_out) > 0"
                  key="2"
                  type=""
                  class="mx-1 is-round link-transactions"
                  effect="dark"
                  round
                >
                  {{ this.$store.state.transaction_out }}
                </el-tag>
                <!-- <span v-if="this.$store.state.transaction_out" class="link-transactions">{{
                  this.$store.state.transaction_out
                }}</span> -->
              </div>
            </router-link>
          </el-menu-item>
          <el-menu-item index="2">
            <router-link to="/tracker-in" class="link">
              <span class="link-text">{{ $t("app.header.trackerIn") }}</span>
              <span class="link-icon">
                <img src="./assets/arrow-down.png" alt="" />
              </span>
              <div v-if="$store.state.transaction_in" class="link-trasactions-wrap">
                <el-tag
                  v-if="Number($store.state.transaction_in) > 0"
                  key="2"
                  type=""
                  class="mx-1 is-round link-transactions"
                  effect="dark"
                  round
                >
                  {{ this.$store.state.transaction_in }}
                </el-tag>
              </div>
            </router-link>
          </el-menu-item>
          <el-menu-item index="3" v-if="isManager">
            <router-link to="/srp-out" class="link">
              <span class="link-text">{{ $t("app.header.srpOut") }}</span>
              <span class="link-icon">
                <img src="./assets/arrow-up.png" alt="" />
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="4" v-if="isManager">
            <router-link to="/srp-in" class="link">
              <span class="link-text">{{ $t("app.header.srpIn") }}</span>
              <span class="link-icon">
                <img src="./assets/arrow-down.png" alt="" />
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="5" v-if="isManager">
            <router-link to="/gcov" class="link">
              <span class="link-text">{{ $t("app.header.gCov") }}</span>
              <span class="link-icon">
                <img src="./assets/arrow-up.png" alt="" />
              </span>
              <span class="link-icon">
                <img src="./assets/arrow-down.png" alt="" />
              </span>
            </router-link>
          </el-menu-item>
          <el-menu-item index="6" v-if="isManager">
            <router-link to="/admin/users" class="link">
              <span class="link-text">{{ $t("app.header.options") }}</span>
              <span class="link-icon">
                <img src="./assets/option.png" alt="" width="16" />
              </span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </div>

      <div class="user-menu">
        <div class="user-menu-item">
          <router-link to="/correspondents" class="link">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.5469 13.6682L16.9882 16.1094C17.1053 16.2399 17.1638 16.3863 17.1638 16.5488C17.1638 16.7113 17.1019 16.8546 16.9782 16.9788C16.8544 17.103 16.7111 17.1649 16.5482 17.1644C16.3853 17.164 16.2388 17.1055 16.1088 16.9888L13.6676 14.5476C11.9359 15.9667 10.0153 16.6144 7.90568 16.4907C5.7961 16.3669 3.96673 15.5042 2.41756 13.9026C0.932977 12.223 0.210268 10.3284 0.249435 8.21881C0.288602 6.10923 1.08298 4.24714 2.63256 2.63256C4.24714 1.08298 6.10923 0.288602 8.21881 0.249435C10.3284 0.210268 12.223 0.932977 13.9026 2.41756C15.5042 3.96714 16.3669 5.79652 16.4907 7.90569C16.6144 10.0149 15.9667 11.9355 14.5476 13.6676L14.5469 13.6682ZM8.37506 15.2501C10.3284 15.198 11.9494 14.5274 13.2382 13.2382C14.5269 11.949 15.1976 10.328 15.2501 8.37506C15.198 6.42173 14.5274 4.80069 13.2382 3.51193C11.949 2.22318 10.328 1.55256 8.37506 1.50006C6.42173 1.55214 4.80068 2.22277 3.51193 3.51193C2.22318 4.8011 1.55256 6.42214 1.50006 8.37506C1.55214 10.3284 2.22277 11.9494 3.51193 13.2382C4.8011 14.5269 6.42214 15.1976 8.37506 15.2501Z"
                fill="#443641"
              />
            </svg>
          </router-link>
        </div>
        <div class="user-menu-item">
          <router-link to="/dict" class="link">
            <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.50002 17.75V16.5H9.25002V17.75H5.50002ZM14.25 7.12498C14.25 8.16664 14.0319 9.14643 13.5956 10.0644C13.1594 10.9823 12.5442 11.7669 11.75 12.4181C11.3725 12.7306 11.0698 13.0985 10.8419 13.5219C10.614 13.9452 10.5 14.4041 10.5 14.8987V15.2506H4.25002V14.9769C4.25002 14.4952 4.15565 14.0394 3.9669 13.6094C3.77815 13.1794 3.50148 12.8212 3.1369 12.535C2.10815 11.7146 1.37252 10.7087 0.930025 9.51748C0.487525 8.32623 0.383358 7.08602 0.617525 5.79685C0.890858 4.44268 1.49961 3.2806 2.44377 2.3106C3.38794 1.3406 4.53711 0.70581 5.89127 0.406226C6.92002 0.184976 7.93565 0.191435 8.93815 0.425601C9.94065 0.659768 10.8521 1.10893 11.6725 1.7731C12.4929 2.43727 13.1277 3.23164 13.5769 4.15623C14.0261 5.08081 14.2506 6.07039 14.2506 7.12498H14.25ZM3.62502 7.74998C3.63794 6.43498 3.97982 5.38352 4.65065 4.5956C5.32148 3.80768 6.22961 3.40081 7.37502 3.37498V2.12498C5.85169 2.16414 4.64398 2.70123 3.7519 3.73623C2.85982 4.77123 2.40086 6.10914 2.37502 7.74998H3.62502Z"
                fill="#443641"
              />
            </svg>
          </router-link>
        </div>
        <div class="profile user-menu-item">
          <span class="profile-name" ref="profileButtonRef">
            {{ $store.state.user_name[0] }}
          </span>
        </div>
      </div>
      <el-popover
        ref="popoverRef"
        :virtual-ref="profileButtonRef"
        trigger="click"
        virtual-triggering
        popper-class="profile-popover"
      >
        <span class="popover-user-initial">{{ $store.state.user_name[0] }}</span>
        <span class="popover-user-name">{{ $store.state.user_name }}</span>
        <span class="popover-user-email">{{ $store.state.user_email }}</span>
        <LocaleSwitcher style="margin-bottom: 5px" />
        <span class="popover-user-button" @click="toggleChangePassword">{{ $t("app.user.toggleBtn") }}</span>
        <el-form v-if="formVisible" :model="form" label-width="120px">
          <el-form-item class="change-password-field">
            <el-input type="password" v-model="form.password" :placeholder="$t('app.user.placeholderNewPass')" />
          </el-form-item>
          <el-form-item class="change-password-field">
            <el-input
              type="password"
              v-model="form.repeatPassword"
              :placeholder="$t('app.user.placeholderRepeatPass')"
            />
          </el-form-item>
          <p v-if="!passwordComplete" class="password-complete">
            {{ $t("app.user.passComplete") }}
          </p>
          <el-form-item class="change-password-field">
            <el-button type="primary" class="change-password-button" @click.prevent="changePasssword">{{
              $t("app.user.passChngBtn")
            }}</el-button>
          </el-form-item>
        </el-form>
        <span class="popover-user-logout">
          <el-button @click="logout">{{ $t("app.user.logoutBtn") }}</el-button>
        </span>
      </el-popover>
    </div>
  </div>
  <router-view />
</template>

<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import auth from "./services/auth";
import requests from "@/services/requests";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import LocaleSwitcher from "./components/LocaleSwitcher.vue";

export default {
  name: "App",
  data() {
    return {
      timer: "",
      formVisible: false,
      passwordComplete: true,
      form: {
        password: "",
        repeatPassword: "",
      },
      sidebarCheck: false,
    };
  },
  components: { ElMessage, LocaleSwitcher },
  methods: {
    ...mapActions(["doLogout", "doLogin"]),
    logout() {
      this.doLogout();
      clearTimeout(this.timer);
      this.removeToken();
    },
    async changePasssword() {
      const { password, repeatPassword } = this.form;
      this.passwordComplete = password === repeatPassword;
      if (this.passwordComplete) {
        await auth.changePassword({
          password,
          user_id: this.$store.state.user_email,
        });
        this.formVisible = false;
        this.form.password = "";
        this.form.repeatPassword = "";
        ElMessage.success(this.$t("app.user.passChngMsg"));
      }
    },
    toggleChangePassword() {
      this.formVisible = !this.formVisible;
    },
    async getAlerts() {
      const { data } = await requests.getUserAlerts({ user_login: this.$store.state.user_email });
      this.$store.state.transaction_out = data.data[0].transaction_out_changed;
      this.$store.state.transaction_in = data.data[0].transaction_in_changed;
      this.$store.state.transaction_out_total = data.data[0].transaction_out_total;
      this.$store.state.transaction_in_total = data.data[0].transaction_in_total;
    },
    async removeToken() {
      const data = await auth.removeToken();
    },
  },
  computed: {
    isManager() {
      return this.$store.state.user_role === "manager" || this.$store.state.user_role === "administrator";
    },
  },
  setup() {
    const activeIndex = ref("1");
    const profileButtonRef = ref();
    const popoverRef = ref();
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return {
      activeIndex,
      profileButtonRef,
      popoverRef,
      t,
    };
  },
  // mounted: function () {
  //   const token = localStorage.getItem("token");
  //   console.log(token);
  //   if (this.$store.state.user_email) {
  //     console.log(this.$store.state.user_email);
  //     this.timer = window.setInterval(() => {
  //       console.log("interval");
  //       console.log(this.timer);
  //       this.getAlerts();
  //     }, 10000);
  //   }
  // },
  mounted: function () {
    console.log("version : abu50/3");
  },

  unmounted: function () {
    this.removeToken();
    clearTimeout(this.timer);
    console.log("unmount");
    this.timer = "";
  },
  watch: {
    "$store.state.locale": function () {
      this.$i18n.locale = this.$store.state.locale || process.env.VUE_APP_I18N_LOCALE;
    },
    "$store.state.token": function () {
      const token = localStorage.getItem("token");
      if (token) {
        this.timer = window.setInterval(() => {
          this.getAlerts();
        }, 10000);
      }
      if (!token) {
        clearTimeout(this.timer);
        this.timer = "";
      }
    },
  },
};
</script>

<style>
@import "reset.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,300;1,400;1,500;1,700&display=swap");
.change-password-field.el-form-item--default .el-form-item__content {
  margin: 0 auto !important;
}
.password-change-success {
  margin-bottom: 10px;
  font-size: 18px;
}
.password-complete {
  color: #fc003d !important;
  margin-bottom: 10px;
}
.change-password-field.el-form-item--default {
  margin-left: auto;
  margin-right: auto;
}
.change-password-button {
  background: #fc003d !important;
  border-color: #fc003d !important;
  color: #fff !important;
}
body {
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
}
.profile-popover {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px !important;
  padding: 0px !important;
  padding-top: 15px !important;
}
.popover-user-initial {
  width: 80px;
  height: 80px;
  background-color: #fc003d;
  border-radius: 50%;
  color: #fff;
  font-size: 48px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.popover-user-name {
  text-align: center;
  font-size: 18px;
  margin-bottom: 5px;
}
.popover-user-email {
  text-align: center;
  margin-bottom: 15px;
}
.popover-user-button {
  border: 1px solid #dcdfe6;
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 15px;
}
.popover-user-logout {
  width: 100%;
  border-top: 1px solid #dcdfe6;
  padding: 10px 0;
  text-align: center;
}
.user-menu {
  display: flex;
  align-items: center;
  height: 59px;
}
.user-menu-item {
  border-left: 1px solid #eaecf1;
  padding: 0 26px;
  display: flex;
  align-items: center;
  height: 59px;
}

.profile-name {
  width: 40px;
  height: 40px;
  background: #fc003d !important;
  border-color: #fc003d !important;
  color: #fff !important;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}
.open-profile {
  cursor: pointer;
}
.link-icon {
  margin-right: 9px;
}

.alert {
  padding: 0 35px;
  border-left: 1px solid #eaecf1;
  border-right: 1px solid #eaecf1;
  height: 100%;
  display: flex;
  align-items: center;
}
.avatar {
  display: flex;
  align-items: center;
  padding: 0 28px;
}
.el-menu--horizontal {
  border: none !important;
  position: relative;
  /*top: 5px;*/
}
.logout-link {
  cursor: pointer;
}
.link-text {
  margin-right: 10px;
}
.header {
  /*max-width: 1200px;*/
  background-color: #fff;
  width: 100%;
}
.header-row {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-right-row {
  display: flex;
  align-items: center;
}
.logo {
  margin: 0;
}
.el-menu--horizontal {
  justify-content: center;
}
.header .el-menu--horizontal > .el-menu-item {
  margin-right: 30px;
}
.header .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #fff !important;
}
.header .el-menu--horizontal > .el-menu-item.is-active {
  background-color: none !important;
  color: #443641 !important;
  padding: 0 10px;
  border-bottom: 3px solid #fc003d !important;
}
.el-menu--horizontal > .el-menu-item {
  color: #8f90a6 !important;
}
.header .el-menu-item * {
  vertical-align: middle;
}
.el-menu-item a {
  text-decoration: none;
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.el-menu-item {
  padding: 0 !important;
}
.link-trasactions-wrap {
  position: relative;
  height: 56px;
  width: 40px;
}
.link-transactions {
  position: relative;
  top: -12px;
  left: 0;
  border-radius: 50%;
  padding: 6px;

  color: var(--white);
  background-color: var(--system-first-500);
  font-size: 10px;
  text-align: center;
}
</style>
