import { instance } from "./auth";

const findCorrespondent = async (value) => {
  const { data } = await instance.post("/requests/find_correspondent", { value });
  return data;
};

const findBankGroup = async (value) => {
  const { data } = await instance.post("/requests/find_bank_group", { value });
  return data;
};

const getTransactionOut = async (value) => {
  const { data } = await instance.post("/requests/changed_transactions_out", { value });
  return data;
};

const getTransactionDetailsByUetr = async (value) => {
  const { data } = await instance.post("/requests/transaction_details_by_uetr", { value });
  return data;
};

// const getTransactionOut = async (value) => {
//     console.log(value);
//     // const {data} = await instance.post("/requests/changed_transactions_out", {value});
//     // return data;
// }

const getTransactionIn = async (value) => {
  const { data } = await instance.post("/requests/changed_transactions_in", { value });
  return data;
};

const getCancellationReasonInformation = async (value) => {
  const { data } = await instance.post("/requests/dict_cancellation_reason_information", { value });
  return data;
};

const getTransactionStatus = async (value) => {
  const { data } = await instance.post("/requests/dict_transaction_status", { value });
  return data;
};

const getTransactionStatusReason = async (value) => {
  const { data } = await instance.post("/requests/dict_transaction_status_reason", { value });
  return data;
};

const getSettlementMethod = async (value) => {
  const { data } = await instance.post("/requests/dict_settlement_method", { value });
  return data;
};

const getSRPParams = async (value) => {
  const { data } = await instance.post("/requests/new_srp_params", { value });
  return data;
};

const createSRP = async (value) => {
  const { data } = await instance.post("/requests/cancel_transaction", { value });
  return data;
};

const createSRPIn = async (value) => {
  const { data } = await instance.post("/requests/transaction_cancellation_status", { value });
  return data;
};

const getGCovParams = async (value) => {
  const { data } = await instance.post("/requests/new_gcov_params", { value });
  return data;
};

const createGCov = async (value) => {
  const { data } = await instance.post("/requests/status_confirmation", { value });
  return data;
};

const getDict = async (dictName, value) => {
  const { data } = await instance.post(`/requests/${dictName}`, { value });
  return data;
};

const getCancellationOut = async (value) => {
  const { data } = await instance.post("/requests/cancellation_out", { value });
  return data;
};

const getCancellationIn = async (value) => {
  const { data } = await instance.post("/requests/cancellation_in", { value });
  return data;
};

const getGCov = async (value) => {
  const { data } = await instance.post("/requests/cover_payments", { value });
  return data;
};
const getCoverDetailsByUetr = async (value) => {
  const { data } = await instance.post("/requests/cover_details_by_uetr", { value });
  return data;
};
const setUserLocale = async (value) => {
  const { data } = await instance.post("/auth/set-locale", { value });
  return data;
};

// WATCH LIST REQUESTS

const watchListPut = async (value) => {
  const data = await instance.post("/requests/watch_list_put", { value });
  return data;
};
const watchListDelete = async (value) => {
  const data = await instance.post("/requests/watch_list_delete", { value });
  return data;
};
const watchListGet = async (value) => {
  const data = await instance.post("/requests/watch_list_get", { value });
  return data;
};
const getUserAlerts = async (value) => {
  const data = await instance.post("/requests/get_user_alerts", { value });
  return data;
};
const getWatchTransactions = async (value) => {
  const data = await instance.post("/requests/get_watch_transactions", { value });
  return data;
};
const getSrpDetailsByUetr = async (value) => {
  const data = await instance.post("/requests/srp_details_by_uetr", { value });
  return data;
};

const getFormShema = async (value) => {
  const { data } = await instance.post("/requests/process-form", { value });
  return data;
};

const putFormData = async (value) => {
  const { data } = await instance.post("/requests/process-start", { value });
  return data;
};

// ADMIN USERS REQUESTS
const getUsersListAdm = async (value) => {
  const { data } = await instance.post("/requests/adm_user_list", { value });
  return data;
};

const getInitUser = async (value) => {
  const { data } = await instance.post("/requests/adm_user_init", { value });
  return data;
};

const getNewUser = async (value) => {
  const { data } = await instance.post("/requests/adm_user_new", { value });
  return data;
};

const getUserById = async (value) => {
  const { data } = await instance.post("/requests/adm_user_by_id", { value });
  return data;
};

const getUpdateUserById = async (value) => {
  const { data } = await instance.post("/requests/adm_user_update", { value });
  return data;
};
const getDeleteUserById = async (value) => {
  const { data } = await instance.post("/requests/adm_user_delete", { value });
  return data;
};
export default {
  getTransactionOut,
  findCorrespondent,
  // getTransactionOut,
  findBankGroup,
  getTransactionDetailsByUetr,
  getTransactionIn,
  getCancellationReasonInformation,
  getTransactionStatus,
  getTransactionStatusReason,
  getSettlementMethod,
  createSRP,
  createSRPIn,
  getSRPParams,
  getGCovParams,
  createGCov,
  getDict,
  getCancellationOut,
  getCancellationIn,
  getGCov,
  getCoverDetailsByUetr,
  setUserLocale,
  watchListPut,
  watchListDelete,
  watchListGet,
  getUserAlerts,
  getWatchTransactions,
  getSrpDetailsByUetr,
  getFormShema,
  putFormData,
  getUsersListAdm,
  getInitUser,
  getNewUser,
  getUserById,
  getUpdateUserById,
  getDeleteUserById,
};
