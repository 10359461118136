<template>
  <select class="lang_list" v-model="$i18n.locale" :onChange="changeLang">
    <option class="lang_list-item" v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
      {{ locale }}
    </option>
  </select>
</template>

<script>
import { mapActions } from "vuex";
import requests from "../services/requests";

export default {
  name: "LocaleSwitcher",
  data() {
    return { locales: ["uk", "en"] };
  },
  methods: {
    ...mapActions(["changeLocale"]),
    changeLang(e) {
      const langue = this.$i18n.locale;
      this.changeLocale(langue);
      this.setLocal(langue);
      // window.location.reload()
    },

    async setLocal(langue) {
      try {
        const data = await requests.setUserLocale({ user_id: this.$store.state.user_email, locale: langue });
        // this.$notify({ type: "success", text: data.message });
      } catch (error) {
        console.log({ error });
        this.$notify({ type: "error", text: error.response.data.message });
      }
    },
  },
};
</script>
<style scoped>
.lang_list {
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  width: 7em;
  height: 1.5em;
  padding: 0 4em 0 1em;
  background: url(../img/icon.svg)
      no-repeat right 0.8em center / 1.4em,
    linear-gradient(to left, var(--primary-300) 35px, var(--primary-200) 35px);
  color: black;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  
}
.lang_list:focus {
  outline: none;
}
.lang_list::-ms-expand {
  display: none;
}
.lang_list-item {
  color: inherit;
  background-color: var(--primary-200);
}

</style>
