<template>
  <div class="login">
    <div class="login-form">
      <div class="form-group">
        <span class="text-gray-600 form-label">{{ $t("loginPage.login") }}</span>
        <el-input v-model="user_id" class="w-50 m-2" :placeholder="$t('loginPage.placeholderLog')" />
      </div>
      <div class="form-group">
        <span class="text-gray-600 form-label">{{ $t("loginPage.password") }}</span>
        <el-input v-model="password" class="w-50 m-2" type="password" :placeholder="$t('loginPage.placeholderPass')" />
      </div>
      <div class="password-fail" v-if="$store.state.wrongPassword">
        <p v-if="$store.state.status_code === 401">{{ $t("loginPage.wrongPass") }}</p>
        <p v-if="$store.state.status_code === 404">{{ $t("loginPage.wrongLogin") }}</p>
        <p v-if="$store.state.status_code && $store.state.status_code !== 401 && $store.state.status_code !== 404">
          {{ $t("loginPage.serverError") }}
        </p>
      </div>
      <div class="button-group">
        <el-button type="primary" class="btn" @click="enter">{{ $t("loginPage.enterBtn") }}</el-button>
        <!-- <span class="button-group-link" @click="rememberPassword">
          Нагадати пароль?
        </span> -->
      </div>
    </div>
  </div>

  <div v-if="$store.state.loading" class="preloader-container">
    <Loader />
  </div>
</template>

<script>
import { ref } from "vue";
import { mapActions, mapGetters } from "vuex";
import { useI18n } from "vue-i18n";
import Loader from "@/components/Loader";

export default {
  name: "Login",
  components: { Loader },
  methods: {
    ...mapActions(["doLogin"]),
    async enter() {
      const { user_id, password } = this;
      console.log("login");
      const value = {
        user_id,
        password,
      };
      this.doLogin(value);
    },
    // rememberPassword() {
    //   this.$router.push("/remember-password");
    // },
  },
  setup() {
    const user_id = ref("");
    const password = ref("");
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "local",
    });

    return {
      user_id,
      password,
      t,
    };
  },
  mounted() {
    console.log("here");
    const currentLang = window.location.href.split("").reverse().join("").slice(0, 2);
    if (currentLang === "ne") {
      this.$i18n.locale = "en";
    } else if (currentLang === "ku") {
      this.$i18n.locale = "uk";
    } else {
      this.$i18n.locale = process.env.VUE_APP_I18N_LOCALE || "en";
    }
  },
};
</script>

<style scoped>
.password-fail {
  color: #fc003d;
  margin-bottom: 15px;
}
.preloader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn {
  background-color: #fc003d !important;
  border-color: #fc003d !important;
}
.login {
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  min-width: 300px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 10px;
  color: #443641;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-button--primary {
  width: 100px;
}

.button-group-link {
  border-bottom: 1px solid;
  cursor: pointer;
}
</style>
