import { createStore } from "vuex";
import router from "@/router";

import auth from "../services/auth";

export default createStore({
  state() {
    return {
      token: "",
      user_role: "",
      user_name: "",
      user_email: "",
      wrongPassword: false,
      locale: "",
      transaction_out: "",
      transaction_in: "",
      transaction_out_total: "",
      transaction_in_total: "",
      loading: false,
      status_code: "",
    };
  },
  getters: {
    getLocale(state) {
      return state.locale;
    },
  },
  mutations: {
    login(state, { token, user_role, user_name, user_email }) {
      state.token = token;
      state.user_role = user_role;
      state.user_name = user_name;
      state.user_email = user_email;
    },
    logout(state) {
      localStorage.setItem("token", "");
      localStorage.setItem("user_role", "");
      state.token = "";
      state.user_role = "";
    },
    loginFail(state, error) {
      state.wrongPassword = true;
      if (error.response?.status) {
        state.status_code = error.response.status;
      } else {
        state.status_code = error;
      }
    },
    locale(state, lang) {
      state.locale = lang;
    },
    loading(state, boolean) {
      state.loading = boolean;
    },
    updateAccessToken(state, value) {
      state.token = value;
    },
  },
  actions: {
    async doLogin({ commit }, loginData) {
      try {
        commit("loading", true);
        commit("loginFail", "");
        const { token, user_role, user_name, user_email, locale } = await auth.login(loginData);
        localStorage.setItem("token", token);
        commit("login", { token, user_role, user_name, user_email });
        commit("locale", locale);
        router.push("/dashboard");
        commit("loading", false);
      } catch (error) {
        // commit("loginFail", error.response?.status);
        commit("loginFail", error);
        // commit("loginStop", error.response?.data.error);
        commit("updateAccessToken", null);
        commit("loading", false);
      }
    },
    async fetchAccessToken({ commit }) {
      const token = localStorage.getItem("token");
      try {
        const { token: backendToken, user_role, user_name, user_email, locale } = await auth.getCurrent(token);
        commit("login", { token: backendToken, user_role, user_name, user_email });
        commit("locale", locale);
      } catch (error) {
        router.push("/");
        commit("logout");
      }
    },
    doLogout({ commit }) {
      localStorage.removeItem("token");
      localStorage.removeItem("user_role");
      localStorage.removeItem("user_role");
      commit("logout");
      router.push("/");
    },
    changeLocale({ commit }, lang) {
      commit("locale", lang);
    },
  },
  modules: {},
});
